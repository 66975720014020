export const tableMixin = {
  data() {
    return {
      tableClasses: {
        table:
          "min-w-full  text-darkblue divide-y md:text-center divide-gray-100 shadow-sm border-gray-200 border",
        thead: "border-b-2 md:text-xs border-darkblue ",
        theadTr: "",
        theadTh:
          "px-3 py-2 font-bold md:text-center whitespace-nowrap text-xs text-left bg-transparent ",
        tbody: "bg-white divide-y divide-gray-100",
        tr: "cursor-pointer hover:bg-lightblue rounded-lg text-xs",
        td: "px-3 py-2  whitespace-no-wrap",
        tfoot: "",
        tfootTr: "",
        tfootTd: "",
      },
    };
  },
  methods: {
    setTableMode(mode) {
      switch (mode) {
        case "hover":
          this.tableClasses.tr =
            "cursor-pointer hover:bg-lightblue rounded-lg text-xs";

          break;
        case "no-hover":
          this.tableClasses.tr = "rounded-lg text-xs";

          break;

        case "dark-no-hover":
          this.tableClasses.tr = "rounded-lg text-xs";

          break;

        default:
          this.tableClasses.tr = "rounded-lg text-xs";
          break;
      }
    },
  },
};
