<template>
  <div class="relative">
    <h4 class="text-center mt-3">Customer's Profile</h4>
    <div class="p-3 md:p-6 rounded-lg">
      <div class="flex flex-col md:flex-row md:justify-center">
        <div class="bg-darkblue rounded-lg">
          <div class="p-3">
            <p class="md:text-xs text-white mb-2">
              <span class="font-bold"> Name:</span> {{ customer.customerName }}
            </p>
            <p class="md:text-xs text-white mb-2">
              <span class="font-bold"> Email: </span>{{ customer.email }}
            </p>
            <p class="md:text-xs text-white mb-2">
              <span class="font-bold"> Phone:</span> {{ customer.phoneNumber }}
            </p>
            <p class="md:text-xs text-white mb-2">
              <span class="font-bold"> Address:</span> {{ customer.address }}
            </p>
            <p class="md:text-xs text-white mb-2">
              <span class="font-bold"> No of invoices: </span
              >{{ getCustomersInvoice.totalCount }}
            </p>
            <div class="mt-2">
              <button
                @click="EditCustomer"
                type="button"
                class="bg-green-600 hover:bg-lightblue hover:text-black mr-2 md:text-xs text-white rounded-lg pt-1 pb-1 pl-2 pr-2"
              >
                Edit
              </button>
              <button
                @click="showStatementModal = true"
                type="button"
                class="bg-green-600 mb-2 hover:bg-lightblue hover:text-black mr-2 md:text-xs text-white rounded-lg pt-1 pb-1 pl-2 pr-2"
              >
                Generate Account Statement
              </button>
              <button
                @click="showBulkPaymentModal = true"
                type="button"
                class="bg-green-600 mb-2 hover:bg-lightblue hover:text-black mr-2 md:text-xs text-white rounded-lg pt-1 pb-1 pl-2 pr-2"
              >
                Make Payment
              </button>
              <button
                @click="showDeleteModal = true"
                type="button"
                class="bg-red-600 md:text-xs hover:bg-lightblue hover:text-black text-white rounded-lg pt-1 pb-1 pl-2 pr-2"
              >
                Delete
              </button>
            </div>
          </div>
          <div v-if="loading.customer">
            <svg
              class="ml-auto mr-auto animate-spin"
              width="25"
              height="25"
              viewBox="0 0 50 50"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M48 25C48 12.2975 37.7025 2 25 2"
                stroke="black"
                stroke-width="4"
              />
              <path
                d="M48 25C48 37.7025 37.7025 48 25 48"
                stroke="white"
                stroke-width="4"
              />
              <path
                d="M2 25C2 12.2975 12.2975 2 25 2"
                stroke="white"
                stroke-width="4"
              />
              <path
                d="M25 48C12.2975 48 2 37.7025 2 25"
                stroke="#C9000C"
                stroke-width="4"
              />
            </svg>
          </div>
        </div>
        <div>
          <div class="overflow-auto md:overflow-visible mt-4 md:mt-0">
            <h4 class="text-center mt-4 block md:hidden">Invoices</h4>

            <t-table
              id="my-table"
              :classes="table_classes"
              :headers="table_header"
              :data="table_data"
            >
              <template slot="column" slot-scope="props">
                <td
                  @click="NavigateToSingleInvoice(props)"
                  :class="props.tdClass"
                >
                  {{ props.text }}
                </td>
              </template>
            </t-table>
          </div>
          <div>
            <transition name="slide-fade">
              <NotificationModal
                v-if="showDeleteModal"
                :modalData="modalData"
                @modal-success="DeleteCustomer($event)"
                @modal-close="CloseModal($event)"
              />
            </transition>
          </div>
          <div v-if="loading.search" class="flex justify-center flex-col">
            <div>
              <p class="text-red-700 text-center md:text-xs font-bold">
                Loading,please wait...
              </p>
            </div>
            <div class="mt-2">
              <svg
                class="ml-auto mr-auto animate-spin"
                width="25"
                height="25"
                viewBox="0 0 50 50"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M48 25C48 12.2975 37.7025 2 25 2"
                  stroke="black"
                  stroke-width="4"
                />
                <path
                  d="M48 25C48 37.7025 37.7025 48 25 48"
                  stroke="white"
                  stroke-width="4"
                />
                <path
                  d="M2 25C2 12.2975 12.2975 2 25 2"
                  stroke="white"
                  stroke-width="4"
                />
                <path
                  d="M25 48C12.2975 48 2 37.7025 2 25"
                  stroke="#C9000C"
                  stroke-width="4"
                />
              </svg>
            </div>
          </div>

          <div
            v-if="
              getCustomersInvoice.result.length < 1 && loading.search != true
            "
            class="mb-6 mt-2"
          >
            <p class="text-center md:text-xs">
              No invoices found for this customer
            </p>
          </div>
          <div class="mb-6 mt-2 text-center">
            <button
              :class="
                search.hasPrevious != true
                  ? 'cursor-not-allowed'
                  : 'cursor-pointer'
              "
              :disabled="
                getCustomersInvoice.hasPrevious != true ||
                loading.search != false
              "
              @click="Navigate('prev')"
              class="text-darkblue md:text-xs hover:text-blue-600 font-bold"
            >
              Prev
            </button>

            <span class="ml-2 mr-2 md:text-xs"
              >{{ currentPage }}/{{ number_of_pages }}</span
            >
            <button
              :class="
                getCustomersInvoice.hasMore != true
                  ? 'cursor-not-allowed'
                  : 'cursor-pointer'
              "
              :disabled="
                getCustomersInvoice.hasMore != true || loading.search != false
              "
              @click="Navigate('next')"
              class="text-darkblue md:text-xs hover:text-blue-600 font-bold"
            >
              Next
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="relative">
      <t-modal
        @closed="showStatementModal = false"
        :fixedClasses="fixedClasses"
        :classes="defaultClasses"
        v-model="showStatementModal"
        header="Generate Customer's statement"
      >
        <div class="text-sm p-l-1 pr-2">
          <h6 class="text-sm mb-3">
            Select the date range below and the statement will be sent to your
            mail
          </h6>
          <div class=" ">
            <t-datepicker
              :classes="datePickerDefaultClass"
              :dateFormat="'Y-m-d H:i:S'"
              :userFormat="'Y-m-d'"
              :placeholder="'Select date range'"
              :range="true"
              v-model="selectedDate"
            />
          </div>
        </div>
        <template v-slot:footer>
          <div class="flex justify-between">
            <button
              @click="showStatementModal = false"
              class="text-red-700 mr-2 text-xs pl-3 pr-3 pt-1 pb-1"
              type="button"
            >
              Cancel
            </button>
            <button
              type="button"
              @click="PrintStatementPdf"
              class="bg-darkblue flex justify-between ml-2 rounded-lg text-xs pl-3 pr-3 pt-2 pb-2 text-white"
            >
              <div v-if="loading.statementPdf" class="mr-2">
                <svg
                  class="ml-auto mr-auto animate-spin"
                  width="18"
                  height="18"
                  viewBox="0 0 50 50"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M48 25C48 12.2975 37.7025 2 25 2"
                    stroke="black"
                    stroke-width="4"
                  />
                  <path
                    d="M48 25C48 37.7025 37.7025 48 25 48"
                    stroke="white"
                    stroke-width="4"
                  />
                  <path
                    d="M2 25C2 12.2975 12.2975 2 25 2"
                    stroke="white"
                    stroke-width="4"
                  />
                  <path
                    d="M25 48C12.2975 48 2 37.7025 2 25"
                    stroke="#C9000C"
                    stroke-width="4"
                  />
                </svg>
              </div>
              <span> Generate Statement </span>
            </button>
          </div>
        </template>
      </t-modal>
    </div>
    <div class="mt-3 p-3 md:p-6">
      <div class="overflow-auto md:overflow-visible md:mt-0">
        <h5 class="text-darkblue text-center">Customer Account Statement</h5>
        <t-table
          id="my-table"
          :classes="tableClasses"
          :headers="tableHeader"
          :data="tableData"
        >
        </t-table>
      </div>
    </div>
    <div>
      <CustomerBulkPaymentModal
        :CloseBulkPaymentModal="CloseBulkPaymentModal"
        :showModal="showBulkPaymentModal"
        :loading="loading.bulkPayment"
        :ReturnPaymentData="SetPaymentData"
      />
    </div>
  </div>
</template>

<script>
  import { mapMutations } from "vuex";

  import gql from "graphql-tag";
  import NotificationModal from "../../components/NotificationModal.vue";
  import CustomerBulkPaymentModal from "../../components/CustomerBulkPaymentModal.vue";
  import { permissionMixin } from "@/assets/helpers/permissionMixin";
  import { tableMixin } from "@/assets/helpers/tableMixin";
  import { modalMixin } from "@/assets/helpers/modalMixin";

  import { datePickerMixin } from "@/assets/helpers/datePickerMixin";

  export default {
    name: "SingleCustomer",
    props: {},
    NotificationModal,
    components: { NotificationModal, CustomerBulkPaymentModal },
    mixins: [permissionMixin, tableMixin, datePickerMixin, modalMixin],

    apollo: {
      getCustomersInvoice: {
        query: gql`
          query getCustomersInvoice($query: CustomersInvoiceInput!) {
            getCustomersInvoice(query: $query) {
              result {
                _id
                serialId
                authorId {
                  _id
                  name
                }
                customerId {
                  customerName
                }
                createdAt
                completedPayment
                invoiceTotal
                createdAt
              }
              totalCount
              hasMore
              hasPrevious
            }
          }
        `,
        variables() {
          return {
            query: {
              cursor: Number(this.searchCursor),
              limit: this.searchData.limit,
              type: this.searchType,
              customerId: this.$route.params.id,
            },
          };
        },
        skip() {
          if (this.appPermissions["invoice"]) {
            return this.appPermissions["invoice"]["read"] == false;
          }
          return true;
        },
        error(error) {
          this.$emit("show-alert", {
            type: "error",
            message: error.message,
          });
        },
        watchLoading(isLoading, countModifier) {
          if (isLoading === true && countModifier === 1) {
            this.loading.search = true;
            return;
          }

          this.loading.search = false;
        },
      },
      getCustomerAccountStatement: {
        query: gql`
          query getCustomerAccountStatement($customerId: ID!) {
            getCustomerAccountStatement(customerId: $customerId) {
              invoiceTotal
              invoiceBalance
            }
          }
        `,
        variables() {
          return {
            customerId: this.$route.params.id,
          };
        },
        error(error) {
          this.$emit("show-alert", {
            type: "error",
            message: error.message,
          });
        },
        skip() {
          if (this.appPermissions[`invoice`]) {
            return this.appPermissions[`invoice`]["read"] == false;
          }
          return true;
        },
        watchLoading(isLoading, countModifier) {
          if (isLoading === true && countModifier === 1) {
            this.loading.accountStatement = true;
            return;
          }

          this.loading.accountStatement = false;
        },
      },

      getCustomerPrintableStatement: {
        query: gql`
          query getCustomerPrintableStatement(
            $query: CustomerPrintableStatementInput!
          ) {
            getCustomerPrintableStatement(query: $query) {
              status
            }
          }
        `,
        variables() {
          return {
            query: {
              customerId: this.$route.params.id,
              dateFilter: this.formattedDateFilter,
            },
          };
        },
        skip() {
          return this.skipStatementQuery;
        },
        error(error) {
          this.$emit("show-alert", {
            type: "error",
            message: error.message,
          });
        },
        result({ loading, errors }) {
          if (!loading && !errors) {
            this.$emit("show-alert", {
              type: "success",
              message:
                "Your statement will be generated and sent to your mail when it's ready",
            });
          }
        },
        watchLoading(isLoading, countModifier) {
          if (isLoading === true && countModifier === 1) {
            this.loading.statementPdf = true;
            return;
          }

          this.showStatementModal = false;
          this.loading.statementPdf = false;
        },
      },
      customer: {
        query: gql`
          query customer($id: ID!) {
            customer(id: $id) {
              customerName
              phoneNumber
              address
              email
            }
          }
        `,
        variables() {
          return {
            id: this.$route.params.id,
          };
        },
        error(error) {
          this.$emit("show-alert", {
            type: "error",
            message: error.message,
          });
        },
        watchLoading(isLoading, countModifier) {
          if (isLoading === true && countModifier === 1) {
            this.loading.customer = true;
            return;
          }

          this.loading.customer = false;
        },
      },
    },
    data() {
      return {
        showDeleteModal: false,
        showBulkPaymentModal: false,
        tableData: [],
        paymentData: {},
        tableHeader: [
          {
            id: "",
            value: "analysis",
            text: "Analysis.",
            className: "",
          },
          {
            value: "amount",
            text: "Amount(₦)",
            className: "fortunate-table",
          },
        ],
        getCustomerAccountStatement: {
          invoiceTotal: 0,
          invoiceBalance: 0,
        },

        modalData: {
          title: "Delete Customer",
          message:
            "Are you sure you want to delete this customer? You can't get it back if you continue.",
          cancelText: "Cancel",
          successText: "Delete",
          loading: false,
        },
        formattedDateFilter: {
          from: "",
          to: "",
        },
        searchData: {
          limit: 5,
          customerId: "",
          cursor: 0,
        },
        searchType: "GT",
        loading: {
          search: false,
          customer: false,
          accountStatement: false,
          statementPdf: false,
          bulkPayment: false,
        },
        searchCursor: 0,
        showStatementModal: false,
        selectedDate: [],
        search: {
          result: [],
          hasMore: false,
          hasPrevious: false,
        },
        customer: {},
        getCustomersInvoice: {
          result: [],
          hasMore: false,
          hasPrevious: false,
          totalCount: 0,
        },
        currentPage: 1,
        skipStatementQuery: true,

        table_header: [
          {
            id: "invoice-no",
            value: "invoice_no",
            text: "Invoice No.",
            className: "",
          },
          {
            value: "amount",
            text: "Amount(₦)",
            className: "fortunate-table",
          },
          {
            value: "customer_name",
            text: "Customer",
            className: "fortunate-table",
          },
          {
            value: "status",
            text: "Status",
          },
        ],
        table_data: [],

        mode: "new",
        table_dropdown_class: {
          button:
            "block font-black break-normal px-4 py-2 text-black transition duration-100 ease-in-out bg-transparent border border-transparent rounded  hover:text-green-400 focus:border-blue-500 focus:ring-2 focus:ring-blue-500 focus:outline-none focus:ring-opacity-50 disabled:opacity-50 disabled:cursor-not-allowed",
          wrapper: "inline-flex flex-col",
          dropdownWrapper: "relative z-10",
          dropdown:
            "origin-top-left absolute left-0 w-32 rounded shadow bg-white mt-1",
          enterClass: "",
          enterActiveClass:
            "transition ease-out duration-100 transform opacity-0 scale-95",
          enterToClass: "transform opacity-100 scale-100",
          leaveClass: "transition ease-in transform opacity-100 scale-100",
          leaveActiveClass: "",
          leaveToClass: "transform opacity-0 scale-95 duration-75",
        },

        table_classes: {
          table:
            "min-w-full  text-darkblue divide-y md:text-center divide-gray-100 shadow-sm border-gray-200 border",
          thead: "border-b-2 md:text-xs border-darkblue ",
          theadTr: "",
          theadTh:
            "px-3 py-2 font-bold md:text-center text-left bg-transparent ",
          tbody: "bg-white divide-y divide-gray-100",
          tr: "cursor-pointer hover:bg-dashboardbody  rounded-lg text-xs",
          td: "px-3 py-2  whitespace-no-wrap",
          tfoot: "",
          tfootTr: "",
          tfootTd: "",
        },

        // paginated_table_data: [],
        icons: {
          pen: require("@/assets/imgs/icons/white-pen.svg"),
        },
      };
    },
    computed: {
      new_mode: function () {
        return this.mode === "new";
      },
      old_mode: function () {
        return this.mode === "old";
      },
      number_of_pages: function () {
        return Math.ceil(
          Number(this.getCustomersInvoice.totalCount) /
            Number(this.searchData.limit)
        );
      },
    },
    methods: {
      ...mapMutations(["setBreadcrumbs"]),
      CloseBulkPaymentModal() {
        this.showBulkPaymentModal = false;
      },
      async SetPaymentData(paymentData) {
        this.paymentData = paymentData;
        await this.MakeInvoiceBulkPayment();
      },

      async PrintStatementPdf() {
        if (this.selectedDate.length > 0) {
          if (this.selectedDate.length > 1) {
            this.formattedDateFilter = {
              from: new Date(this.selectedDate[0]).getTime(),
              to: new Date(this.selectedDate[1]).getTime(),
            };
            this.skipStatementQuery = false;
            return;
          }
          this.formattedDateFilter = {
            from: new Date(this.selectedDate[0]).getTime(),
            to: new Date(this.selectedDate[0]).getTime(),
          };
          this.skipStatementQuery = false;
        } else {
          return this.$emit("show-alert", {
            type: "error",
            message: "Select a date range before you can perform this action",
          });
        }
      },

      NavigateToSingleInvoice(row) {
        this.$router.push({
          name: "single_invoice",
          params: { id: this.getCustomersInvoice.result[row.rowIndex]._id },
        });
      },
      async DeleteCustomer() {
        this.modalData.loading = true;

        try {
          const { data, errors } = await this.$apollo.mutate({
            // Query
            mutation: gql`
              mutation ($id: ID!) {
                deleteCustomer(id: $id) {
                  _id
                  serialId
                  isDeleted
                }
              }
            `,
            // Parameters
            variables: {
              id: this.$route.params.id,
            },
          });

          if (!errors && !!data.deleteCustomer._id) {
            this.$emit("show-alert", {
              type: "success",
              message: `Customer deleted successfully`,
            });

            return this.$router.push("/app/customers");
          }
          this.modalData.loading = false;

          if (!!errors && data == null) {
            const errorResponse = errors.find((error) => {
              return error.extensions.code == "BAD_USER_INPUT";
            });
            return this.$emit("show-alert", {
              type: "error",
              message: errorResponse.message,
            });
          }

          this.$emit("show-alert", {
            type: "error",
            message: `Error deleting customer,check your network or contact your developer`,
          });
        } catch (error) {
          this.modalData.loading = false;

          this.$emit("show-alert", {
            type: "error",
            message: `Error deleting customer,check your network or contact your developer`,
          });
        }
      },
      async MakeInvoiceBulkPayment() {
        this.loading.bulkPayment = true;

        try {
          const { data, errors } = await this.$apollo.mutate({
            // Query
            mutation: gql`
              mutation makeBulkInvoicePayment(
                $customerId: ID!
                $payment: PaymentInput!
              ) {
                makeBulkInvoicePayment(
                  customerId: $customerId
                  payment: $payment
                ) {
                  invoiceId
                  serialId
                  amount
                }
              }
            `,
            // Parameters
            variables: {
              customerId: this.$route.params.id,
              payment: this.paymentData,
            },
          });

          this.showBulkPaymentModal = false;

          this.loading.bulkPayment = false;

          if (!errors && !!data) {
            const messages = data.makeBulkInvoicePayment.map((payment) => {
              return `Invoice No: ${
                payment.serialId
              }, Amount(₦): ${payment.amount.toLocaleString()}`;
            });
            this.paymentData = {};

            this.$emit("show-alert", {
              type: "success",
              message: messages,
              title: "Payment made successfully for the following invoices",
            });

            this.$apollo.queries.getCustomersInvoice.refetch();
            this.$forceUpdate();

            return;
          }

          if (!!errors && data == null) {
            return this.$emit("show-alert", {
              type: "error",
              message: errors[0].message,
            });
          }

          this.$emit("show-alert", {
            type: "error",
            message: `Error making bulk payment ,check your network or contact your developer`,
          });
        } catch (error) {
          this.showBulkPaymentModal = false;

          this.loading.bulkPayment = false;

          this.$emit("show-alert", {
            type: "error",
            message: `Error making bulk payment ,check your network or contact your developer`,
          });
        }
      },
      CloseModal() {
        this.showDeleteModal = false;
        this.modalData.loading = false;
      },
      Navigate(type) {
        switch (type) {
          case "prev":
            this.searchType = "LT";
            this.currentPage--;

            this.searchCursor = this.search.result[0].createdAt;
            break;

          case "next":
            this.searchType = "GT";
            this.currentPage++;
            this.searchCursor =
              this.search.result[this.search.result.length - 1].createdAt;
            break;

          default:
            break;
        }
      },
      TableButton() {},
      EditCustomer() {
        this.$router.push({
          name: "manage_customer",
          params: { mode: "edit", id: this.$route.params.id },
        });
      },
      FormatTableData(data) {
        this.table_data = data.map((invoice) => {
          return {
            _id: invoice._id,
            invoice_no: invoice.serialId,
            amount: invoice.invoiceTotal.toLocaleString(),
            customer_name: invoice.customerId.customerName,
            status: invoice.completedPayment === true ? "paid" : "pending",
            btn: "btn",
          };
        });
      },
      ToggleChartSpinner() {
        this.chart_loading = !this.chart_loading;
      },

      ChangeCustomerMode(mode) {
        this.mode = mode;
      },
    },
    watch: {
      getCustomersInvoice(newValue) {
        this.FormatTableData(newValue.result);
      },
      getCustomerAccountStatement: {
        deep: true,
        handler(accountStatement) {
          this.tableData = [
            {
              analysis: " Invoice Total",
              amount: accountStatement.invoiceTotal.toLocaleString(),
            },
            {
              analysis: " Invoice Balance",
              amount: accountStatement.invoiceBalance.toLocaleString(),
            },
          ];
        },
      },
    },
    mounted() {
      this.$apollo.queries.getCustomersInvoice.setOptions({
        fetchPolicy: "network-only",
      });
      this.setTableMode("no-hover");
      this.setBreadcrumbs([
        { displayName: "Customers", pathName: "customers" },
        {
          displayName: "Single Customer",
          pathName: "single_customer",
          params: { id: this.$route.params.id },
        },
      ]);
    },
  };
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  table {
    border-collapse: separate;
    border-spacing: 0 0.5rem;
  }
</style>
