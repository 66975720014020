<template>
  <div
    class="left-0 h-full pl-0 pr-0 md:pt-20 md:pb-20  fortunate-modal-bg z-10 rounded-lg  right-0 absolute top-0 ml-auto mr-auto   "
  >
    <div
      class="bg-white md:w-8/12 w-10/12 mt-5 p-6 mr-auto ml-auto mb-4 rounded-lg"
    >
      <div class="text-right">
        <button
          :class="{ 'cursor-not-allowed': loadingStatus }"
          :disabled="modalData.loading"
          type="button"
          @click="ModalClose"
          class=" mr-2 "
        >
          <font-awesome-icon
            title="Close"
            :class="'text-red-500 text-base hover:text-black '"
            :icon="['fas', 'times']"
          />
        </button>
      </div>
      <h1 class="font-bold text-lg mb-2 text-center text-darkblue">
        {{ modalData.title }}
      </h1>
      <p class="text-center text-sm mb-2">{{ modalData.message }}</p>
      <div class="text-center">
        <button
          :disabled="modalData.loading"
          :class="{ 'cursor-not-allowed': loadingStatus }"
          @click="ModalClose"
          type="button"
          class="mr-4 pl-2 pr-2 text-sm pt-1 pb-1  text-red-600 hover:bg-red-600 hover:text-white border-2 border-red-600  rounded-lg"
        >
          {{ modalData.cancelText }}
        </button>
        <button
          :disabled="modalData.loading"
          :class="{ 'cursor-not-allowed': loadingStatus }"
          @click="ModalSuccess"
          type="button"
          class="pl-2 pr-2 text-sm pt-1 pb-1 bg-green-600 border-green-600 hover:bg-darkblue hover:border-darkblue  text-white border-2  rounded-lg"
        >
          <font-awesome-icon
            v-if="modalData.loading"
            title="Close"
            :class="'text-white animate-spin text-base mr-1 '"
            :icon="['fas', 'spinner']"
          />
          {{ modalData.successText }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
  import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

  export default {
    name: "NotificationModal",
    props: {
      modalData: Object,
    },
    data() {
      return {
        showLoader: false,
      };
    },
    components: { FontAwesomeIcon },
    computed: {
      loadingStatus: function() {
        return this.modalData.loading;
      },
    },
    methods: {
      ModalClose() {
        this.$emit("modal-close", {});
      },
      ModalSuccess() {
        this.$emit("modal-success", {});
      },
    },
    mounted() {
      // alert("inner" + window.innerWidth + ", Outer" + window.innerWidth);
    },
  };
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  .fortunate-card {
    background-repeat: no-repeat;
    background-size: cover;
  }
</style>
